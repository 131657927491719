import { AnimatePresence } from 'framer-motion';

import { deviceNumberSizes } from 'config/device';
import { useWindowSize } from 'hooks/useWindowResize';

import {
  SidebarContainer,
  SidebarContent,
  SidebarOverlayContainer,
} from './Drawer.style';

const variants = {
  open: () => ({
    opacity: 1,
    transform: 'translateX(0%)',
  }),
  closed: () => ({ opacity: 1, transform: 'translateX(-100%)' }),
};

type DrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  renderHeader: () => React.ReactNode;
  renderInfo: () => React.ReactNode;
  children: React.ReactNode;
  renderFooter: () => React.ReactNode;
};

function Drawer({
  isOpen,
  onClose,
  renderHeader,
  renderInfo,
  children,
  renderFooter,
}: DrawerProps) {
  const windowSize = useWindowSize();
  const isTabletDown =
    (windowSize.width ? windowSize.width : 0) <= deviceNumberSizes.tablet;

  return (
    <>
      {isOpen && isTabletDown && (
        <SidebarOverlayContainer key='overlay' onClick={onClose} />
      )}
      {/* animation fix workaround */}
      <AnimatePresence>
        {isOpen && (
          <SidebarContainer
            initial='closed'
            exit='closed'
            animate={isOpen ? 'open' : 'closed'}
            variants={variants}
            transition={{ duration: 0.3, ease: 'linear' }}
            custom={window.innerWidth}
          >
            <SidebarContent>
              <div className='header'>{renderHeader()}</div>
              <div className='info'>{renderInfo()}</div>
              <div className='children'>{children}</div>
              <div className='footer'>{renderFooter()}</div>
            </SidebarContent>
          </SidebarContainer>
        )}
      </AnimatePresence>
    </>
  );
}

export default Drawer;
