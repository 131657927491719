import { useEffect, useState, useCallback } from 'react';

import format from 'date-fns/format';

import { DetailDateInput } from 'components/DateInput';
import EopyyDetail from 'components/Eopyy/EopyyDetail';
import { CustomInput } from 'components/shared/Input/Input.style';

type EditableCellProps = {
  row: any;
  column: any;
  value: any;
  handleTableUpdate: (args: any) => void;
  handleEopyySuccess: (args: any) => void;
};

export const EditableCell = ({
  row,
  column,
  value: initialValue,
  handleTableUpdate, // This is a custom function that we supplied to our table instance
  handleEopyySuccess,
}: EditableCellProps) => {
  const fieldCaption = column.Header;
  const { ID, fields = {} } = row.original;

  const isEditable = Array.isArray(fields.editable)
    ? fields.editable.some((item: any) => item.FIELDCAPTION === fieldCaption)
    : false;
  const isEopyy = Array.isArray(fields.eopyy)
    ? fields.eopyy.find((eopyyField: any) => eopyyField.FIELDCAPTION === fieldCaption)
    : false;
  const isDate = Array.isArray(fields.date)
    ? fields.date.some((item: any) => item.FIELDCAPTION === fieldCaption)
    : false;
  const isReadOnly = Array.isArray(fields.readOnly)
    ? fields.readOnly.some((item: any) => item.FIELDCAPTION === fieldCaption)
    : false;

  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);
  const onChange = (e: any) => setValue(e.target.value);
  // We'll only update the external data when the input is blurred
  const onBlur = (e: any) => {
    const selectedValue = { FIELDVALUE: e.target.value, DISPLAYVALUE: e.target.value };
    handleTableUpdate({ storeId: ID, fieldCaption, value: selectedValue });
  };
  const handleFieldUpdate = useCallback(
    (selectedValue: any, displayValue: any) => {
      handleTableUpdate({
        storeId: ID,
        fieldCaption,
        value: {
          FIELDVALUE: selectedValue,
          DISPLAYVALUE: displayValue || selectedValue,
        },
      });
    },
    [ID, fieldCaption, handleTableUpdate],
  );
  // If the initialValue is changed external, sync it up with our state
  useEffect(() => setValue(initialValue), [initialValue]);

  if (!isEditable || isReadOnly) {
    if (value instanceof Date) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return format(value, 'dd/MM/yyyy');
    }
    return value || '';
  }
  if (isDate) {
    return <DetailDateInput {...{ value, handleFieldUpdate }} />;
  }
  if (isEopyy) {
    return (
      <EopyyDetail
        storeId={ID}
        eopyyInfo={isEopyy.eopyyInfo}
        handleUpdate={handleFieldUpdate}
        handleSuccess={handleEopyySuccess}
        {...{ value, onChange }}
      />
    );
  }
  if (isEditable && fieldCaption === 'ΝΑΙ/ΟΧΙ') {
    //console.log(fields.editable[1].FIELDVALUE)
    const isYesActive = fields.editable[1].FIELDVALUE === '1';
    const isNoActive = fields.editable[1].FIELDVALUE === '0';
    const [yesActive, setYesActive] = useState(isYesActive);
    const [noActive, setNoActive] = useState(isNoActive);
    useEffect(() => {
      setYesActive(isYesActive);
      setNoActive(isNoActive);
    }, [isYesActive]);
    const handleButtonClick = (newValue: any) => {
      if ((newValue === '1' && !yesActive) || (newValue === '0' && !noActive)) {
        setYesActive(newValue === '1');
        setNoActive(newValue === '0');
        onChange({ target: { value: newValue } });
        onBlur({ target: { value: newValue } });
      }
    };
    const yesButtonStyle = {
      width: '30px',
      padding: '2px 2px',
      marginRight: '2px',
      border: '0.5px solid rgba(0,0,0,0.3)',
      backgroundColor: yesActive ? 'rgba(7,157,214,0.5)' : 'transparent',
    };
    const noButtonStyle = {
      width: '30px',
      alignItems: 'center',
      padding: '2px 2px',
      border: '0.5px solid rgba(0,0,0,0.3)',
      backgroundColor: noActive ? 'rgba(7,157,214,0.5)' : 'transparent',
    };
    return (
      <div>
        <button style={yesButtonStyle} onClick={() => handleButtonClick('1')}>
          &#10003;
        </button>
        <button style={noButtonStyle} onClick={() => handleButtonClick('0')}>
          &#10007;
        </button>
      </div>
    );
  }
  return (
    <CustomInput
      style={{
        padding: 0,
        color: 'inherit',
        boxShadow: 'none',
        backgroundColor: 'inherit',
      }}
      className='tableInputCell'
      {...{ value, onChange, onBlur }}
    />
  );
};
