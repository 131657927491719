import Lottie from 'react-lottie';
import styled from 'styled-components';

import { device } from 'config/device';
import { useUserConfiguration } from 'config/themes';
import { Version } from 'config/Version';
import animationData from 'lotties/Locker.json';

type FooterContainerProps = {
  $screen: string;
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const FooterContainer = styled.footer<FooterContainerProps>`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.small};
  ${(props) => {
    if (props.$screen === 'login') {
      return {
        position: 'relative',
        bottom: '20px',
        right: '20px',
        justifyContent: 'center',
        color: props.theme.gray,
      };
    }
    return {
      alignItems: props.$screen === 'drawer' ? 'center' : 'flex-start',
      flexDirection: 'column',
    };
  }}
  @media ${device.tablet} {
    ${(props) => {
      if (props.$screen === 'login') {
        return {
          paddingLeft: '0.5rem',
          justifyContent: 'flex-start',
        };
      }
    }}
  }
`;
const BrandBox = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;
  margin-right: 10px;
  span {
    font-size: ${({ theme }) => theme.large};
  }
  .wrapper {
    gap: 0.5rem;
    display: flex;
    align-items: center;
  }
`;

type VersionBoxProps = {
  $screen: string;
};

const VersionBox = styled.div<VersionBoxProps>`
  font-size: ${({ theme }) => theme.small};
  ${(props) => {
    if (props.$screen === 'login') {
      return {
        position: 'absolute',
        right: '1rem',
      };
    } else if (props.$screen === 'leftMenuStandar') {
      return {
        fontSize: '12px',
        color: props.theme.gray,
        right: '1rem',
      };
    }
    return {
      color: props.theme.gray,
    };
  }}
`;

type FooterProps = {
  screen?: string;
};

function Footer({ screen = 'default' }: FooterProps) {
  const { title } = useUserConfiguration();
  return (
    <FooterContainer $screen={screen} className='footerContainer'>
      {screen === 'login' && (
        <Lottie
          options={defaultOptions}
          style={{ position: 'absolute', right: 0, bottom: 0 }}
          height={100}
          width={100}
        />
      )}
      <VersionBox $screen={screen}>
        {title} Version: {Version}
      </VersionBox>
    </FooterContainer>
  );
}

export default Footer;
