import React from 'react';

import { HiOutlineArrowLeft, HiOutlineChevronRight } from 'react-icons/hi';
import { useNavigate, Link } from 'react-router-dom';

import { BreadcrumbsWrapper } from './Breadcrumbs.style';

type BreadcrumbsProps = {
  data: Array<{
    label: string;
    value?: string;
  }>;
  handleCancel?: () => void;
};

function Breadcrumbs({ data, handleCancel }: BreadcrumbsProps) {
  const navigate = useNavigate();
  const handleBack = () => {
    // navigate(-1); // Navigates to the previous page in the history stack
    // TODO: Maybe check if previous page is external, not our app
    // Go home for now
    navigate('/');
  };

  return (
    <BreadcrumbsWrapper>
      <div className='goBackBtn' onClick={handleBack}>
        <HiOutlineArrowLeft />
        Back
      </div>
      <div
        className='cancelBtn'
        onClick={() => {
          if (typeof handleCancel === 'function') {
            handleCancel();
          } else {
            handleBack();
          }
        }}
      >
        Cancel
      </div>
      <div className='breadcrumbsList'>
        {data.map((bread, idx) => (
          <React.Fragment key={idx}>
            {bread?.value ? (
              <Link className='breadcrumbLink' to={bread.value}>
                {bread.label}
              </Link>
            ) : (
              <p>{bread.label}</p>
            )}
            {data.length !== idx + 1 && <HiOutlineChevronRight />}
          </React.Fragment>
        ))}
      </div>
    </BreadcrumbsWrapper>
  );
}
export default Breadcrumbs;
