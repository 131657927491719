import styled from 'styled-components';

import { device } from 'config/device';

type BubbleWrapperProps = {
  $lineWidth: number;
};

const StepsInfoContainer = styled.ul<BubbleWrapperProps>`
  width: 100%;
  background: #fff;
  border-radius: 12px;
  height: 150px;
  display: flex;
  padding: 1rem 10%;
  grid-gap: 0.5rem;
  overflow-y: hidden;
  overflow-x: auto;
  justify-content: ${({ $lineWidth }) =>
    $lineWidth > 0 ? 'space-between' : 'flex-start'};

  @media ${device.mobile} {
    padding: 1rem;
    height: auto;
    background: none;

    button {
      height: auto;
      font-size: 18px;
    }

    .stepsNavSelect {
      width: 100%;

      .stepsNavSelect__control {
        border-radius: 8px;
        border: 2px solid ${(props) => props.theme.special};
        color: red;
      }

      .stepsNavSelect__single-value {
        color: ${(props) => props.theme.special};
      }

      .stepsNavSelect__dropdown-indicator {
        color: ${(props) => props.theme.special};
      }
    }
  }
`;

export { StepsInfoContainer };
