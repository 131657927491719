import { createGlobalStyle } from 'styled-components';

import { device } from './device';
import defaultTheme from './themes';

const GlobalStyle = createGlobalStyle`
  * {
      margin: 0;
      padding: 0;
      box-sizing: inherit;
  }
  html {
    font-size: 62.5%;
    @media ${device.mobile} {
      font-size: 56.8%;
    }
    @media ${device.tablet} {
      font-size: 58.8%;
    }
  }
  body {
      font-weight: 500;
      font-size: 1.4rem;
      font-family: "Roboto",'Open Sans' ,sans-serif;
      overflow: hidden;
      box-sizing: border-box;
      background-color: ${(props) => props.theme.grayLight};

      h3 {
        font-weight: normal;
      }
      
      .stepsNavSelect__menu {
        border-radius: 8px;
        border: 2px solid ${(props) => props.theme.special};
        overflow: hidden;
        
        .stepsNavSelect__option {
          margin: 5px 10px;
          width: calc(100% - 20px);
        }
        .stepsNavSelect__option--is-selected {
          border-radius: 8px;
          background-color: ${(props) => props.theme.special};
          color: #fff;
          .option {
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }
      }
  }
  *::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }
  *::-webkit-scrollbar-track {
    background: ${(props) => props.theme.grayLight};
  }
  *::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.gray2};
  }
`;

export default GlobalStyle;
