import { useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import { useUserConfiguration } from 'config/themes';
import useIsOverflow from 'hooks/useIsOverflow';

import { NavbarContainer, NavContent, NavLogo } from './NavBar.style';
import NavLinks from './NavLinks/NavLinks';
import NavMenu from './NavMenu/NavMenu';

type NavBarProps = {
  setMenuExpanded: (expanded: boolean) => void;
};

export default function NavBar({ setMenuExpanded }: NavBarProps) {
  const ref = useRef();
  const isOverFlown = useIsOverflow(ref, () => {});

  const navigate = useNavigate();
  const { logoPath, logoHeight } = useUserConfiguration();

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <NavbarContainer ref={ref}>
      <NavContent>
        <NavLogo
          className='header-logo'
          alt='app-logo'
          src={logoPath}
          $logoHeight={logoHeight}
          onClick={() => navigate('/')}
        />
        {/* <NavMenu isOverFlown={Boolean(isOverFlown)} />
        <NavLinks isOverFlown={Boolean(isOverFlown)} /> */}
      </NavContent>
      <NavMenu isOverFlown={Boolean(true)} setMenuExpanded={setMenuExpanded} />
    </NavbarContainer>
  );
}
