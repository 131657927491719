import { HiOutlineCollection } from 'react-icons/hi';
import { MdGroup, MdGroupOff } from 'react-icons/md';
import { TbWalletOff } from 'react-icons/tb';

import { PlaceHolderSvg, SortDown, SortUp } from 'components/shared/Icons/ArrowIcons';
import { StyledFlex } from 'components/shared/shared.style.';
import Checkbox from 'components/shared/ui/CheckBox';

import {
  getAllRows,
  getValidRows,
  getIsAllChecked,
  checkIfapproval,
} from '../Approval/apprvoval.utils';
import { GroupWrapper, THwrapper } from '../Table.style';

type Props = {
  column: any;
  tableRows: Array<any>;
  approvalState: any;
};

export const RenderHeader = ({ column, tableRows, approvalState }: Props) => {
  return (
    <StyledFlex $gridGap='1rem' $alignItems='flex-start' justifyContent='start'>
      {(() => {
        if (!column.canGroupBy) return null;
        if (checkIfapproval(column.id)) {
          const allRows = getAllRows(tableRows);
          const validRows = getValidRows(tableRows);
          const isChecked = getIsAllChecked(validRows, approvalState.data);
          return (
            <Checkbox
              data-cy={`checkbox-${column.id}`}
              isGeneral={true}
              checked={isChecked}
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              // @ts-ignore
              onClick={() => approvalState.onMultiChange(allRows)}
            />
          );
        }
        return (
          <GroupWrapper {...column.getGroupByToggleProps()}>
            {(() => {
              if (column.isGrouped) return <TbWalletOff />;
              return <HiOutlineCollection />;
            })()}
          </GroupWrapper>
        );
      })()}
      <THwrapper {...column.getSortByToggleProps()}>
        {column.render('Header')}
        {(() => {
          if (!column.isSorted) return <PlaceHolderSvg />;
          if (column.isSortedDesc) return <SortDown />;
          return <SortUp />;
        })()}
      </THwrapper>
    </StyledFlex>
  );
};
