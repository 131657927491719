import styled from 'styled-components';

import { device } from 'config/device';

export const ListPageWrapper = styled.div`
  background: #fff;
  padding: 16px 32px;
  border-radius: 12px;
  margin-top: 20px;

  @media ${device.mobile} {
    padding: 8px 12px;
  }

  .disclaimerTitle {
    padding-bottom: 10px;
  }
  .disclaimerDesc {
    font-size: 14px;
    color: ${(props) => props.theme.gray3};
    padding-bottom: 10px;
  }
`;
