import { Link } from 'react-router-dom';

import { deviceNumberSizes } from 'config/device';
import { useWindowSize } from 'hooks/useWindowResize';

type ExpandableAccountProps = {
  closeMenu: () => void;
};

function ExpandableAccount({ closeMenu }: ExpandableAccountProps) {
  const windowSize = useWindowSize();
  const isTabletDown =
    (windowSize.width ? windowSize.width : 0) <= deviceNumberSizes.tablet;

  function handleClick() {
    if (isTabletDown) closeMenu();
  }
  return (
    <>
      <Link to='branch-selection' onClick={() => handleClick()} className='actionBtn'>
        Change Company
      </Link>
      <Link to='change-password' onClick={() => handleClick()} className='actionBtn'>
        Change Password
      </Link>
    </>
  );
}

export default ExpandableAccount;
