import { memo, useMemo } from 'react';

import { useActor } from '@xstate/react';
import { useNavigate } from 'react-router-dom';
import StepWizard from 'react-step-wizard';

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import FinalScreen from 'components/FinalScreen/FinalScreen';
import Page from 'components/Page/Page';
import { Container } from 'components/shared/Layouts/Layout';
import AskUser from 'components/shared/Modal/askUser';
import StepsNav from 'components/StepsInfo/StepsNav/StepsNav';
import { StepsProvider } from 'context/StepsProvider';
import { findRightPath } from 'helpers/helpers';
import useAskUser from 'hooks/useAskUser';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  actorRef: any;
  listPath?: string;
};

function ChildStepsAsPage({ isOpen, onClose, actorRef, listPath }: Props) {
  const navigate = useNavigate();

  const [appState, send] = useActor(actorRef);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { object } = appState.context;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isFinalState = appState.matches('final');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const askOnTransition = useAskUser(appState.context, isFinalState);

  const pages = useMemo(() => {
    if (!object) return [];
    const objectPages = object.PAGES.map((page: any, index: number) => (
      <Page key={page.PAGENUM} stepIndex={index + 1} page={page} />
    ));
    return [...objectPages, <FinalScreen onClose={onClose} key='Final' />];
  }, [object]);

  return (
    <>
      <Breadcrumbs
        data={[
          { label: 'Home', value: '/' },
          { label: object.OBJECTNAME, value: findRightPath(object) },
          { label: object.TITLE },
        ]}
        key='child-steps-breadcrumbs'
        handleCancel={() => {
          if (askOnTransition) {
            // trigger prompt
            return navigate('/', { replace: true });
          }
          listPath && navigate(listPath);
          return onClose();
        }}
      />
      <StepsProvider appState={appState} appSend={send}>
        <Container data-testid='child-steps'>
          <AskUser isActive={askOnTransition} />
          <StepWizard
            nav={<StepsNav pages={object.PAGES} send={send} />}
            isLazyMount={true}
            transitions={{
              enterRight: 'enter',
              enterLeft: 'enter',
              exitRight: 'exit',
              exitLeft: 'exit',
            }}
            initialStep={1}
          >
            {pages}
          </StepWizard>
        </Container>
      </StepsProvider>
    </>
  );
}

export default memo(ChildStepsAsPage);
