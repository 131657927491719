import styled from 'styled-components';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

type IconProps = {
  isGeneral?: boolean;
};

const Icon = styled.svg<IconProps>`
  fill: none;
  stroke: ${(props) => (props.isGeneral ? props.theme.special : 'white')};
  stroke-width: 2px;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

type StyledCheckboxProps = {
  checked: boolean;
  isGeneral?: boolean;
};

const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  transition: all 150ms;

  ${({ theme, checked, isGeneral }) => {
    if (isGeneral) {
      return {
        background: checked ? theme.white : theme.primary,
        border: `2px solid ${theme.special}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      };
    }
    return {
      background: checked ? theme.special : '#fffff',
      border: checked ? 'initial' : `2px solid ${theme.special}`,
    };
  }}
  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

type CheckboxProps = {
  className?: string;
  checked: boolean;
  isGeneral?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Checkbox = ({ className, checked, isGeneral, ...props }: CheckboxProps) => (
  <CheckboxContainer className={className} {...props}>
    <HiddenCheckbox defaultChecked={checked} />
    <StyledCheckbox checked={checked} isGeneral={isGeneral}>
      <Icon viewBox='0 0 24 24' isGeneral={isGeneral}>
        <polyline points='20 6 9 17 4 12' />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

export default Checkbox;
