import { useMemo } from 'react';

import { useUserConfiguration } from 'config/themes';
import { SoftOneBoolean } from 'helpers/helpers';

import {
  createListData,
  createTableData,
  createDetailData,
  createTableColumns,
} from './Table.utils';
import Table from '../Table';
import { TablePageWrapper, MemoryTableWrapper } from '../Table.style';
import { EditableCell } from '../TableViews/EditableCell';

const Cell = EditableCell;

type TablePickerProps = {
  type: string;
  keyData: any;
  valueData: any;
  actions?: any;
  readOnly?: boolean;
  eopyyInfo?: any;
  tableOffset?: number;
  enableA11y?: boolean;
  handleUpdate?: any;
  handleClickRow?: any;
  handleEopyySuccess?: any;
  handleOneItem?: any;
};

const TablePicker = ({
  type,
  keyData,
  valueData,
  actions = undefined,
  readOnly = false,
  eopyyInfo,
  tableOffset,
  enableA11y = true,
  handleUpdate,
  handleClickRow,
  handleEopyySuccess,
  handleOneItem,
}: TablePickerProps) => {
  const { showTableFilter, defaultPageSize } = useUserConfiguration();

  const tableColumns = useMemo(() => {
    if (!keyData) return [];
    if (type === 'List') {
      return createTableColumns(keyData, 'name', 'caption');
    }
    if (type === 'Detail') {
      return createTableColumns(keyData, 'FIELDCAPTION', null);
    }
    return createTableColumns(keyData, 'dataIndex', 'header');
  }, [keyData, type]);

  const tableData = useMemo(() => {
    if (!valueData) return [];
    if (type === 'List') {
      return createListData(valueData, keyData);
    }
    if (type === 'Detail') {
      return createDetailData(valueData, 'FIELDCAPTION', eopyyInfo);
    }
    return createTableData(valueData, keyData);
  }, [keyData, valueData, type, eopyyInfo]);

  if (type === 'List')
    return (
      <MemoryTableWrapper $offset={tableOffset}>
        <Table
          usage={{ filter: true, enableA11y: true }}
          tableProps={{
            disableGroupBy: true,
            initialState: { hiddenColumns: ['FLD-1', 'ID'] },
          }}
          data={tableData}
          columns={tableColumns}
          provideCell={handleClickRow}
        />
      </MemoryTableWrapper>
    );
  if (type === 'Detail') {
    return (
      <TablePageWrapper
        $isClickable={handleClickRow}
        $height={50}
        className='detailTableWrapper'
      >
        <Table
          handleOneItem={handleOneItem ? handleOneItem : undefined}
          usage={{ enableA11y }}
          tableProps={{
            disableGroupBy: true,
            defaultColumn: { Cell },
            handleTableUpdate: handleUpdate,
            handleEopyySuccess,
            initialState: { hiddenColumns: ['ID'] },
          }}
          data={tableData}
          columns={tableColumns}
          provideCell={handleClickRow}
          actions={!readOnly && actions}
        />
      </TablePageWrapper>
    );
  }
  return (
    <TablePageWrapper
      $isClickable={handleClickRow}
      $offset={tableOffset}
      className='defaultTableWrapper'
    >
      <Table
        usage={{
          enableA11y,
          pagination: true,
          columnsFilter: true,
          filter: SoftOneBoolean(showTableFilter),
        }}
        tableProps={{
          initialState: {
            hiddenColumns: ['FLD-1', 'ID'],
            pageSize: Number(defaultPageSize ?? 10),
          },
        }}
        data={tableData}
        columns={tableColumns}
        provideCell={handleClickRow}
        {...{ actions }}
      />
    </TablePageWrapper>
  );
};

export default TablePicker;
