import { enGB } from 'date-fns/locale';
import { DatePicker } from 'react-nice-dates';

import 'react-nice-dates/build/style.css';
import { ConfigBaseItem } from 'components/shared/ConfigBaseItem';
import { CalendarIcon } from 'components/shared/Icons/InfoIcons';
import { ConfigInput } from 'components/shared/Input/ConfigInput.style';
import { getValidDateValue } from 'helpers/helpers';

import { DateWrapper } from './DateInput.style';

type Props = {
  item: any;
  isDisabled?: boolean;
  handleChange: (date: Date | null) => void;
  children?: any;
};

export function DateInput({ item, isDisabled, handleChange, children }: Props) {
  return (
    <DateWrapper className='dateWrapper'>
      <DatePicker
        locale={enGB}
        format='dd/MM/yyyy'
        date={getValidDateValue(item.DISPLAYVALUE)}
        onDateChange={handleChange}
      >
        {({ inputProps, focused }) => (
          <ConfigBaseItem
            item={item}
            render={children}
            leftIcon={<CalendarIcon />}
            className={'inputDateContainer'}
          >
            {({ baseStatus }) => (
              <ConfigInput
                data-cy={`date-${item.FIELDID}`}
                data-testid='input'
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                modifiers={baseStatus.status}
                className={'input' + (focused ? ' -focused' : ' ')}
                {...inputProps}
                disabled={isDisabled}
              />
            )}
          </ConfigBaseItem>
        )}
      </DatePicker>
    </DateWrapper>
  );
}
