import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

export const INPUT_MODIFER_CONFIG = {
  error: ({ theme }: any) => css`
    border: 1px solid ${theme.error};
  `,
  success: ({ theme }: any) => css`
    border: 1px solid ${theme.green};
  `,
};

type ConfigInputProps = {
  $isNumber?: boolean;
  $noIcon?: boolean;
};

export const ConfigInput = styled.input<ConfigInputProps>`
  width: 100%;
  font-family: 'Roboto';
  color: ${(props) => props.theme.text};
  letter-spacing: 0.3px;
  outline: none;
  margin-top: 0.5rem;
  padding: 1.4rem 1.5rem;
  font-size: ${(props) => props.theme.small};
  font-weight: 500;
  border-radius: 2px;
  background-color: ${(props) => props.theme.grayLight2};
  box-shadow: ${({ theme }) => theme.shadow[3]};
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition:
    background-color,
    box-shadow 0.2s ease-in-out;
  text-align: ${(props) => (props.$isNumber ? 'right' : 'left')};
  padding-left: ${(props) => (props.$noIcon ? '1.4rem' : '3.8rem')};
  padding-right: 3.8rem;
  border-radius: 8px;
  ${applyStyleModifiers(INPUT_MODIFER_CONFIG)}
  ${(props) => {
    if (!props.disabled) return null;
    return { pointerEvents: 'none' };
  }}
`;
