import styled from 'styled-components';

import { ClipLoader } from 'components/shared/Loading';
import { WithAnimation } from 'helpers/routeTransitions/MountTransition';

const LoaderDiv = styled('div')`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

type AnimatedTextProps = {
  show: boolean;
  title: string;
};

export function AnimatedText({ show, title }: AnimatedTextProps) {
  const AnimatedText = WithAnimation(Text);
  function Text() {
    return <h2>{title}</h2>;
  }
  if (!show) return null;
  return <AnimatedText />;
}

type NotificationLoadingProps = {
  showText: boolean;
  textTitle: string;
};

export function NotificationLoading({ showText, textTitle }: NotificationLoadingProps) {
  return (
    <>
      <LoaderDiv className='notificationLoading'>
        <ClipLoader size={70} />
      </LoaderDiv>
      <div style={{ marginTop: 'auto' }}>
        <AnimatedText show={showText} title={textTitle} />
      </div>
    </>
  );
}
