import { useMemo } from 'react';

import { useMachine } from '@xstate/react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import useResizeObserver from 'use-resize-observer';

import { Button } from 'components/shared/Button/Button';
import Input from 'components/shared/Input/Input';
import ErrorPage from 'components/shared/Layouts/Error/ErrorPage';
import { NotificationLoading } from 'components/shared/ui/AnimatedText';
import { useAuth } from 'context/AuthProvider';

import { passwordMachine } from './passwordMachine';
import { BranchContainer } from '../BranchPage/Branch.style';
import { Form, FormMessage, LoginError } from '../LoginPage/Login.style';

export default function PasswordPage() {
  const navigate = useNavigate();
  const { ref } = useResizeObserver();

  const authState = useAuth();
  const { user } = authState.context;
  if (!user) {
    return (
      <ErrorPage
        title='Go to Home'
        message='Error 404 - Page Not Found'
        handler={() => (window.location.href = '/')}
      />
    );
  }

  const { register, handleSubmit, formState } = useForm({
    shouldUnregister: false,
  });
  const [state, send] = useMachine(passwordMachine, {
    actions: {
      goToHome: () => {
        navigate('/', { replace: true });
      },
    },
  });
  const { hasMatchError, responseError } = state.context as any;

  return (
    <BranchContainer ref={ref}>
      <Form
        onSubmit={handleSubmit(async (data) => {
          send({ type: 'SUBMIT', ...data });
        })}
      >
        <FormMessage color='tertiary'>Change password</FormMessage>
        {(() => {
          if (state.hasTag('loading') || state.matches('success')) {
            return (
              <NotificationLoading
                showText={state.matches('success')}
                textTitle={'SUCCESS'}
              />
            );
          }
          return (
            <>
              <Input
                type='password'
                label='Current Password *'
                placeholder='Your current password...'
                $errorMessage='current password is required'
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                error={formState.errors.oldPassword}
                {...register('oldPassword', { required: true })}
              />
              <Input
                type='password'
                label='New Password *'
                placeholder='Your new password...'
                errorMessage='new password is required'
                error={formState.errors.newPassword || hasMatchError}
                {...register('newPassword', { required: true })}
              />
              <Input
                type='password'
                label='Confirm Password *'
                placeholder='Your new password...'
                errorMessage='confirm password is required'
                error={formState.errors.confirmedPassword || hasMatchError}
                {...register('confirmedPassword', { required: true })}
              />
              <Button style={{ width: '100%', marginTop: '2rem' }} type='submit'>
                SUBMIT
              </Button>
              {state.hasTag('failure') && <LoginError>{responseError}</LoginError>}
            </>
          );
        })()}
      </Form>
    </BranchContainer>
  );
}
