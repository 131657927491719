import styled from 'styled-components';

import { ReactComponent as LockedClosedIcon } from 'assets/icons/lock-closed.svg';
import { device } from 'config/device';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
  width: 50%;
  input[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }
  @media ${device.mobile} {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  &.inputDateContainer {
    width: 100%;
  }
  &.geolocationContainer {
    width: 100%;
  }
`;

export const Label = styled.div`
  text-align: right;
  color: ${(props) => props.theme.gray2};
  font-size: ${(props) => props.theme.small};
  font-weight: 700;
`;

type ContentContainerProps = {
  $isDisabled?: boolean;
  $hasRender?: boolean;
};

export const ContentContainer = styled.div<ContentContainerProps>`
  flex: 0.9;
  pointer-events: ${(props) => (props.$isDisabled ? 'none' : 'auto')};
  width: 90%;
  ${(props) => {
    if (props.$hasRender) return { gap: '1.5rem', display: 'flex' };
    return null;
  }}
  @media ${device.tablet} {
    ${(props) => {
      if (props.$hasRender) return { gap: '0.5rem', flexDirection: 'column' };
      return null;
    }}
  }
  @media ${device.mobile} {
    width: 100%;
    margin-left: 0;
  }
`;

type LabelContainerProps = {
  $renderToTop?: boolean;
};

export const LabelContainer = styled.div<LabelContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0.3;
  ${(props) => {
    if (!props.$renderToTop) return null;
    return { marginTop: '1.4rem', alignSelf: 'flex-start' };
  }};
`;

export const LockedIcon = styled(LockedClosedIcon)`
  height: 1.7rem;
  width: 1.7rem;
  margin-left: 0.5rem;
  fill: ${(props) => props.theme.gray};
`;
