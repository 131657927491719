import styled, { css } from 'styled-components';

import { ReactComponent as Key } from 'assets/icons/key.svg';
import { ReactComponent as User } from 'assets/icons/user.svg';
import { device } from 'config/device';

const svgStyle = css`
  height: 2rem;
  width: 2rem;
  fill: ${(props) => props.theme.gray2};
`;

const svgPosition = css`
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-40%);
`;
export const KeyIcon = styled(Key)`
  ${svgStyle}
  ${svgPosition}
`;
export const UserIcon = styled(User)`
  ${svgStyle}
  ${svgPosition}
`;
export const LoginContainer = styled.div`
  width: 100rem;
  height: 50rem;
  min-height: 50rem;
  max-width: 97%;
  margin: 6rem auto;
  display: flex;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: ${(props) => props.theme.shadow[3]};
  @media ${device.tablet} {
    width: 50rem;
  }
  @media ${device.mobile} {
    height: auto;
    margin: 2rem auto;
  }
`;

export const FormMessage = styled.p`
  font-weight: 700;
  font-size: ${(props) => props.theme.x_large};
  position: relative;
  margin-bottom: 3rem;
  :after {
    content: '';
    position: absolute;
    width: 5rem;
    height: 0.5rem;
    border-radius: 20px;
    bottom: -1.5rem;
    right: 50%;
    transform: translateX(50%);
  }
  position: relative;
  text-align: center;
  &:after {
    content: '';
    position: absolute;
    width: 5rem;
    height: 0.5rem;
    background: rgb(15, 27, 43);
    border-radius: 20px;
    right: 50%;
    transform: translateX(50%);
    bottom: -1.5rem;
  }
`;

type FormProps = {
  resize?: boolean;
};

export const Form = styled.form<FormProps>`
  width: ${(props) => (props.resize ? '100%' : '50%')};
  height: 100%;
  padding: 4rem;
  position: relative;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.9);
  .option {
    gap: 1rem;
    display: flex;
    svg {
      fill: ${(props) => props.theme.special};
    }
  }
  h2 {
    width: 100%;
    text-align: center;
    color: ${(props) => props.theme.special};
  }
  @media ${device.mobile} {
    min-height: 50rem;
    padding: 2rem;
    gap: 4rem;
    justify-content: center;
  }
`;

export const LoginWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;

  .leftContainer {
    flex: 1.5;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background: linear-gradient(#eae8e8, #fefefe);

    @media ${device.laptop} {
      flex: 1;
    }
    @media ${device.tablet} {
      flex: 0;
    }

    .loginImage {
      max-width: 1300px;
      @media ${device.lDesktop} {
        max-width: 70%;
      }
      img {
        width: 100%;
      }
    }
  }
  .rightContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;

    .loginForm {
      max-width: 500px;
      height: initial;
      align-items: flex-start;

      h3 {
        font-size: ${(props) => props.theme.x_large};
        margin-bottom: 6px;
      }
      h4 {
        font-weight: normal;
        color: ${(props) => props.theme.gray};
      }
      .formDescription {
        font-size: 1.6rem;
        color: ${(props) => props.theme.gray};
        font-weight: normal;

        a {
          text-decoration: none;
          color: ${(props) => props.theme.special};
        }
      }

      input {
        border-radius: 8px;
        box-shadow: none;
        border: 1px solid ${(props) => props.theme.grayLight3};
      }
    }
    .branchForm {
      justify-content: center;
      max-width: 500px;

      div {
        border-radius: 8px;
      }
    }
    .loadingForm {
      height: initial;
      .notificationLoading {
        position: initial;
        top: 50%;
        transform: none;
      }
    }
    .footerContainer {
      position: absolute;
    }
  }
`;
export const ParticlesContainer = styled.div`
  width: 50%;
  height: 100%;
  color: #fff;
  position: relative;
  background: ${({ theme }) =>
    `linear-gradient(to top right, ${theme.special}FF, ${theme.special}FF, ${theme.special}CC, ${theme.special}B3)`};
`;

export const LoginError = styled.span`
  color: ${({ theme }) => theme.error};
  text-align: center;
`;
