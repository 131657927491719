import { useState, useCallback, useEffect } from 'react';

import { useMachine } from '@xstate/react';
import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useLocation, useParams, useNavigate } from 'react-router-dom';

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import ChildStepsAsPage from 'components/ChildSteps/ChildStepsAsPage';
import { Error } from 'components/Search/Search.style';
import ErrorPage from 'components/shared/Layouts/Error/ErrorPage';
import { Loading, OverlayLoading } from 'components/shared/Loading';
import TableSearchHeader from 'components/Table/TableNav/TableSearchHeader';
import { Actions } from 'components/Table/TablePicker/TableActions';
import TablePicker from 'components/Table/TablePicker/TablePicker';
import { isCopyIconVisible } from 'config/public';
import { useUserConfiguration } from 'config/themes';
import { ListProvider } from 'context/ListProvider';
import { findRightPath } from 'helpers/helpers';
import {
  WithAnimation,
  WithAnimationList,
} from 'helpers/routeTransitions/MountTransition';
import { getEditData } from 'helpers/services/services.utils';
import useBouncer from 'hooks/useBouncer';
import { useInfoAndData } from 'hooks/useInfoAndData';
import { getConfigByID, getObjectByParams } from 'machines/FormMachine/FormMachine.utils';
import { ListMachine } from 'machines/ListMachine/ListMachine';
import { createLocateObject } from 'machines/shared/utils/locateInfo';

import { ListPageWrapper } from './ListTable.style';
import { useAuth } from '../../context/AuthProvider';
import LoginPage from '../LoginPage/LoginPage';

const AnimatedTable = WithAnimationList(TablePicker);
const AnimatedErrorPage = WithAnimation(ErrorPage);
const AnimatedLogin = WithAnimation(LoginPage);

export function ListTable() {
  const authState = useAuth();
  const { objects, config } = authState.context;
  const iAuthorized = authState.matches('authorized');

  if (!iAuthorized) {
    return <AnimatedLogin />;
  }

  if (objects.length == 0) {
    return <Loading />;
  }

  const { copyIcon, excludeFromCopyIcon } = useUserConfiguration();

  const { objectPath, optionPath, id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [query, setQuery] = useState('');
  const [liveQuery, setLiveQuery] = useState('');
  const setDebouncedSetLiveQuery = useBouncer(setLiveQuery, 500);

  const currentObject = getObjectByParams(objectPath, optionPath, objects);
  const configById = getConfigByID(currentObject.OBJECTID, config);

  const [state, send] = useMachine(ListMachine, {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    context: { ...ListMachine.context, currentObject },
  });

  const { status, data, error, isFetching, refetch } = useInfoAndData({
    query: liveQuery,
    list: currentObject.OBJSOFTONELIST,
    object: currentObject.OBJSOFTONENAME,
    filters: currentObject.OBJSOFTONEFILTERS,
    enabled: currentObject?.HASMENUOPTION,
  });

  const {
    mutate,
    reset,
    status: editStatus,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  } = useMutation(getEditData, {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onError: (error) => toast.error(error.message),
    onSuccess: (editData) => {
      send({
        type: editData.key ? 'spawnForm' : 'spawnCopyForm',
        id: objectPath,
        key: editData.key,
        data: editData,
        config: configById,
        object: currentObject,
        globalConfig: config,
        globalObjects: objects,
        refetch,
      });
      setIsModalOpen(true);
    },
  });

  const handleClick = useCallback(
    async (code: any, copy: any) => {
      const locateObject = createLocateObject(configById, currentObject);
      await mutate({ key: code, copy, locateObject, currentObject });
    },
    [configById, currentObject],
  );

  const location = useLocation();
  const navigate = useNavigate();

  const handleClose = () => {
    if (currentObject?.HASMENUOPTION) {
      refetch();
    }
    reset();
    send({ type: 'CLOSE' });
    setIsModalOpen(false);
  };

  useEffect(() => {
    handleClose();
  }, [location.pathname]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (id) handleClick(id);
  }, [id]);

  const isDetail = Boolean(state.context.childObjectRef);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (status === 'error') return <Error>{error.message}</Error>;
  else if (!currentObject?.HASMENUOPTION)
    return (
      <AnimatedErrorPage
        title='Go to Home'
        message='Error 404 - Page Not Found'
        handler={() => (window.location.href = '/')}
      />
    );
  return (
    <>
      {isDetail && (
        <ChildStepsAsPage
          isOpen={isModalOpen}
          onClose={handleClose}
          actorRef={state.context.childObjectRef}
          listPath={findRightPath(currentObject)}
        />
      )}

      {!isDetail && (
        <Breadcrumbs
          data={[
            { label: 'Home', value: '/' },
            { label: currentObject.OBJECTNAME },
            { label: currentObject.TITLE },
          ]}
        />
      )}
      <ListPageWrapper
        className='listWrapper'
        style={{
          height: '100%',
          overflow: 'hidden',
          display: isDetail ? 'none' : 'initial',
        }}
      >
        <OverlayLoading
          isLoading={editStatus === 'loading' || state.matches('gettingPdf')}
        />
        <ListProvider
          path={currentObject.OBJECTPATH}
          menu={currentObject.MENUOPTIONS}
          form={currentObject.OBJSOFTONEFORM}
          config={configById}
          refetchList={refetch}
        >
          {/* {(() => {
            if (!state.context.childObjectRef) return null;
            return (
              <ChildSteps
                isOpen={isModalOpen}
                onClose={handleClose}
                actorRef={state.context.childObjectRef}
              />
            );
          })()} */}
          <p className='disclaimerTitle'>Choose item</p>
          <p className='disclaimerDesc'>
            Select the item you want from the list below or perform a search.
          </p>
          <TableSearchHeader
            value={query}
            label={currentObject.TITLE}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            length={data?.data.length}
            isUpdating={isFetching && status !== 'loading'}
            handleChange={(e) => {
              setQuery(e.target.value);
              setDebouncedSetLiveQuery(e.target.value);
            }}
          />
          {(() => {
            if (status === 'loading') return <Loading />;
            return (
              <AnimatedTable
                type='Edit'
                tableOffset={10}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                valueData={data.data}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                keyData={data.infoData}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                actions={(id) => {
                  const hasCopyIcon = isCopyIconVisible(
                    currentObject.OBJSOFTONENAME,
                    copyIcon,
                    excludeFromCopyIcon,
                  );
                  const copy = hasCopyIcon && handleClick;
                  const print = Boolean(currentObject.PRINTTEMPLATE);
                  if (!hasCopyIcon && !print) return undefined;
                  return <Actions {...{ id, send, copy, print }} />;
                }}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                handleClickRow={(row) => {
                  // handleClick(row.ID);
                  navigate(`${findRightPath(currentObject)}/${row.ID}`);
                }}
              />
            );
          })()}
        </ListProvider>
      </ListPageWrapper>
    </>
  );
}
