import styled, { css } from 'styled-components';

import { device } from 'config/device';

export const BaseButton = styled.button`
  font-family: inherit;
  font-size: 100%;
  overflow: visible;
  text-transform: none;
  border: 0;
  border-radius: 5px;
  font-weight: 500;
  line-height: 1.2;
  height: 40px;
  white-space: nowrap;
  text-decoration: none;
  padding: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition-property: border-color, background, color, transform, box-shadow;
  transition-duration: 0.15s;
  transition-timing-function: ease;
`;

export const ClearButton = styled(BaseButton)`
  background: ${(props) => props.theme.disable};
  color: ${(props) => props.theme.gray2};
  :hover {
    opacity: 0.7;
  }
`;

export const SaveButton = styled(BaseButton)`
  background: ${(props) => props.theme.special};
  display: flex;
  align-items: center;
  color: white;
  :hover {
    opacity: 0.7;
  }
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const SignatureWrapper = styled.div`
  width: 80%;
  height: auto;
  margin: 0 auto;
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0px auto;
  margin-top: 10px;
  @media ${device.mobile} {
    width: 100%;
  }
`;
