import Lottie from 'react-lottie';

import { WithAnimation } from 'helpers/routeTransitions/MountTransition';
import animationData from 'lotties/NotFound.json';

import {
  FinalScreenContainer,
  ImageWrapper,
  ErrorIcon,
  StyledButton,
  InfoWrapper,
} from './FinalScreen.style';

type Props = {
  message: string;
  handler: () => void;
  title: string;
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const ErrorScreen = ({ message, handler, title }: Props) => {
  return (
    <FinalScreenContainer data-cy='error-container'>
      <ImageWrapper>
        <Lottie options={defaultOptions} />
      </ImageWrapper>
      <InfoWrapper>
        <div className='header'>
          <ErrorIcon />
          <span>Failure</span>
        </div>
        <div className='wrapper'>
          <p>{message}</p>
        </div>
        <StyledButton onClick={handler}>{title}</StyledButton>
      </InfoWrapper>
    </FinalScreenContainer>
  );
};

export default WithAnimation(ErrorScreen);
