import { RiAccountCircleFill, RiAccountCircleLine, RiKeyFill } from 'react-icons/ri';
import styled from 'styled-components';

import { ReactComponent as Link } from 'assets/icons/link.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { device } from 'config/device';

export const AccountIcon = styled(RiAccountCircleFill)`
  height: 3rem;
  width: 3rem;
`;
export const AccountIconLine = styled(RiAccountCircleLine)`
  height: 2.5rem;
  width: 2.5rem;
`;
export const LogoutIcon = styled(Logout)`
  height: 2rem;
  width: 2rem;
  margin-left: 1rem;
  fill: ${(props) => props.theme.gray2} !important;
`;
export const KeyIcon = styled(RiKeyFill)`
  height: 2rem;
  width: 2rem;
  margin-left: 1rem;
  fill: ${(props) => props.theme.gray2} !important;
`;
export const LinkIcon = styled(Link)`
  height: 1.5em;
  width: 1.5rem;
  flex-shrink: 0;
  margin-left: 1rem;
  fill: ${(props) => props.theme.gray2} !important;
`;

//////////////////////////////////////////////

type NavLogoProps = {
  $logoHeight: string;
};

export const NavLogo = styled.img<NavLogoProps>`
  flex-shrink: 0;
  cursor: pointer;
  height: ${({ $logoHeight }) => $logoHeight};
  max-width: 20rem;
  @media ${device.mobile} {
    max-height: 4rem;
  }
`;

export const NavListHeader = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  font-size: ${(props) => props.theme.small};
  color: ${({ theme }) => theme.gray};
`;

export const NavContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2%;
  padding-right: 2%;

  .header-logo {
    display: none;
    @media ${device.mobile} {
      display: initial;
    }
  }
`;
export const NavbarContainer = styled.nav`
  width: 100%;
  min-width: 100%;
  // height: 7rem;
  position: fixed;
  top: 0;
  z-index: 100;
  display: flex;
  // background-color: ${(props) => props.theme.tertiary};
  // box-shadow: ${(props) => props.theme.shadow[3]};
  @media ${device.mobile} {
    height: 6rem;
  }
`;
