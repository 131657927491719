import styled from 'styled-components';

import { device } from 'config/device';

type BooleanLayoutProps = {
  menuExpanded: boolean;
};

const Layout = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'menuExpanded',
})<BooleanLayoutProps>`
  height: 100vh;
  padding: 1rem 1rem;
  margin-left: ${({ menuExpanded }) => (menuExpanded ? '33rem' : '4rem')};
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.3s linear;

  @media ${device.mobile} {
    height: calc(100vh - 6rem - 70px);
    margin-top: 6rem;
    margin-left: 0;
    padding: 0rem 2rem;
  }
`;

const Container = styled.div`
  margin-top: 20px;
`;

export { Layout, Container };
