import { useMemo } from 'react';

import Bool from 'components/Bool/Bool';
import { DateContainer, DATE_TYPES } from 'components/DateInput';
import Detail from 'components/Detail/Detail';
import FilesUpload from 'components/FilesUpload/FilesUploader';
import Link from 'components/Link/Link';
import List from 'components/List/List';
import StringList from 'components/List/StringList';
import Search from 'components/Search/Search';
import SignatureGroup from 'components/Signature/SignatureGroup';
import Sql from 'components/Sql/Sql';
import Text from 'components/Text/Text';
import Textarea from 'components/Textarea/Textarea';
import { useStepsContext, useStepsDispatch } from 'context/StepsProvider';
import { updateRelatedFields } from 'helpers/updateRelatedFields';
import { findDetailMachineRef } from 'machines/DetailMachine/DetailMachine.utils';

import { GroupLabel, GroupContainer, GroupInputs } from './Group.style';
import Geolocation from '../Geolocation/Geolocation';

const SPECIAL_GROUPS = ['File', 'Signature', 'Detail'];

type Props = {
  group: any;
};

function Group({ group }: Props) {
  const state = useStepsContext();
  const send = useStepsDispatch();
  const { config, details, object, calcError } = state.context;

  const groupItems = useMemo(() => {
    const groupConfig = config.filter((configItem: any) => {
      return configItem.PAGEGROUP === group.GROUPDESC;
    });
    return groupConfig.map((item: any) => {
      return { ...item, ...updateRelatedFields(config, item) };
    });
  }, [config, group.GROUPDESC]);

  const isGroupEmpty = useMemo(() => {
    const content = groupItems.filter((groupItem: any) => {
      return !groupItem.REFFIELDID || groupItem.ACTIVE;
    });
    return !content.length;
  }, [groupItems]);
  const isSpecialGroupEmpty = useMemo(() => {
    const { files, tempFiles, signature } = state.context;
    if (group.GROUPLEVEL === 'File') {
      return !files.length && !tempFiles.length;
    }
    if (group.GROUPLEVEL === 'Signature') {
      return !signature;
    }
  }, [state.context]);

  const googleMapsData = useMemo(() => {
    if (group.GROUPLEVEL !== 'GoogleMaps') return null;

    return config
      .filter((configItem: any) => {
        return configItem.PAGEGROUP === group.GROUPDESC;
      })
      .map((item: any) => {
        return item.FIELDVALUE;
      })
      .join(' ');
  }, []);

  if (isSpecialGroupEmpty && group.GROUPREADONLY) return null;
  if (isGroupEmpty && !SPECIAL_GROUPS.includes(group.GROUPLEVEL)) return null;
  return (
    <div>
      <GroupLabel>{group.GROUPDESC}</GroupLabel>
      <GroupContainer className='groupContainer'>
        <GroupInputs className='groupInputs'>
          {(() => {
            if (group.GROUPLEVEL === 'Signature') {
              return (
                <SignatureGroup
                  readOnly={group.GROUPREADONLY}
                  resignature={object.REGSIGNATURE}
                  softoneSubmitKey={group.GROUPFILEOBJ}
                />
              );
            }
            if (group.GROUPLEVEL === 'Detail') {
              return (
                <Detail
                  readOnly={group.GROUPREADONLY}
                  parentConfig={config}
                  machineRef={findDetailMachineRef(details, group.GROUPDESC)}
                  error={calcError}
                />
              );
            }
            if (group.GROUPLEVEL === 'File') {
              return (
                <FilesUpload
                  readOnly={group.GROUPREADONLY}
                  softoneSubmitKey={group.GROUPFILEOBJ}
                />
              );
            }
            return groupItems.map((item: any) => {
              const key = item.FIELDID;
              if (item.REFFIELDID && !item.ACTIVE) {
                return null;
              }
              if (DATE_TYPES.includes(item.FIELDTYPE)) {
                return (
                  <DateContainer key={key} config={groupItems} {...{ item, send }} />
                );
              }
              if (item.FIELDTYPE === 'Search') {
                return <Search key={key} {...{ item, send }} />;
              }
              if (item.FIELDTYPE === 'Bool') {
                return <Bool key={key} {...{ item, send }} />;
              }
              if (item.FIELDTYPE === 'Text') {
                return <Text key={key} {...{ item, send }} />;
              }
              if (item.FIELDTYPE === 'Textarea') {
                return <Textarea key={key} {...{ item, send }} />;
              }
              if (item.FIELDTYPE === 'List') {
                return <List key={key} {...{ item, send }} />;
              }
              if (item.FIELDTYPE === 'String') {
                return <StringList key={key} {...{ item, send }} />;
              }
              if (item.FIELDTYPE === 'Sql') {
                return <Sql key={key} {...{ item, send }} />;
              }
              if (item.FIELDTYPE === 'Link') {
                return <Link key={key} {...{ item, send }} />;
              }
              return null;
            });
          })()}
          {group.GROUPLEVEL === 'GoogleMaps' && <Geolocation address={googleMapsData} />}
        </GroupInputs>
      </GroupContainer>
    </div>
  );
}

export default Group;
