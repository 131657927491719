import { useState, useLayoutEffect, useMemo } from 'react';

import { FaCheck } from 'react-icons/fa';
import { HiArrowRight, HiArrowLeft } from 'react-icons/hi';
import useResizeObserver from 'use-resize-observer';

import { Button } from 'components/shared/Button/Button';
import { Select } from 'components/shared/ui/Select';
import { deviceNumberSizes } from 'config/device';
import { useStepsContext } from 'context/StepsProvider';
import { useWindowSize } from 'hooks/useWindowResize';

import { StepsInfoContainer } from './StepsNav.style';
import BubbleSteps from '../BubbleNavItem/BubbleSteps';

type Props = {
  currentStep?: number;
  pages: any;
  goToStep?: (step: number) => void;
  send: any;
};

export default function StepsNav({ currentStep, pages, goToStep, send }: Props) {
  const [lineWidth, setLineWidth] = useState(0);
  const { value } = useStepsContext();
  const { ref, width } = useResizeObserver();

  useLayoutEffect(() => {
    if ((width ? width : 0) > 0) {
      const currentLineWidth =
        ((width ? width : 0) - (pages.length + 1) * 40) / pages.length;
      setLineWidth(currentLineWidth);
    }
  }, [width, pages.length]);

  const stepsInfoArray = useMemo(() => {
    const array = [];

    function handleNavigation(step: number) {
      if (value === 'final') return;
      send({ type: 'STEP_CHANGE' });
      goToStep && goToStep(step);
    }

    pages.forEach((page: any, index: number) => {
      array.push(
        <BubbleSteps
          key={page.PAGENUM}
          index={index + 1}
          label={page.PAGEDESC}
          current={currentStep ? currentStep : 0}
          $lineWidth={lineWidth}
          goToStep={handleNavigation}
        />,
      );
    });
    array.push(
      <BubbleSteps
        key='final'
        index={pages.length + 1}
        label='Final'
        current={currentStep ? currentStep : 0}
        IconName={FaCheck}
        $lineWidth={0}
        goToStep={() => {}}
      />,
    );
    return array;
  }, [pages, goToStep, currentStep, value, lineWidth, send]);

  const windowSize = useWindowSize();
  const isMobile = (windowSize.width ? windowSize.width : 0) < deviceNumberSizes.mobile;

  const stepsInfoArrayMobile = useMemo(() => {
    const array = [];
    const options: { value: any; label: string }[] = [];

    function handleNavigation(step: number) {
      if (value === 'final') return;
      send({ type: 'STEP_CHANGE' });
      goToStep && goToStep(step);
    }

    const BeforeButton = (
      <Button
        disabled={Boolean(currentStep && currentStep < 2)}
        onClick={() => {
          if (currentStep) handleNavigation(currentStep - 1);
        }}
      >
        <HiArrowLeft />
      </Button>
    );

    const AfterButton = (
      <Button
        disabled={Boolean(currentStep && currentStep === pages.length)}
        onClick={() => {
          if (currentStep) handleNavigation(currentStep + 1);
        }}
      >
        <HiArrowRight />
      </Button>
    );
    array.push(BeforeButton);

    pages.forEach((page: any, index: number) => {
      options.push({
        value: index + 1,
        label: `Step ${index + 1}: ${page.PAGEDESC}`,
      });
    });

    const Component = (
      <Select
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        options={options}
        value={options[currentStep ? currentStep - 1 : 1]}
        menuPortalTarget={document.body}
        className='stepsNavSelect'
        classNamePrefix='stepsNavSelect'
        onChange={(item: any) => {
          handleNavigation(item.value || 1);
        }}
      />
    );
    array.push(Component);
    array.push(AfterButton);

    return array;
  }, [pages, goToStep, currentStep, value, lineWidth, send]);

  return (
    <StepsInfoContainer $lineWidth={lineWidth} ref={ref} data-testid='steps-info'>
      {isMobile ? stepsInfoArrayMobile : stepsInfoArray}
    </StepsInfoContainer>
  );
}
