import styled from 'styled-components';

import { device } from 'config/device';

export const GroupLabel = styled.div`
  flex: 1;
  margin: 2rem 0 1rem 0;
  color: ${(props) => props.theme.gray2};
  font-size: ${(props) => props.theme.medium};
  font-weight: 700;
  @media ${device.mobile} {
    margin-bottom: 1rem;
  }
  margin-right: 1rem;
`;
export const HR = styled.div`
  width: 100%;
  border-top: 4px solid ${(props) => props.theme.special};
`;

export const HRPlain = styled.div`
  margin: 10px 0;
  border-top: 2px solid #d4d4d4;
`;

export const GroupContainer = styled.div`
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  @media ${device.mobile} {
    padding: 1.5rem 0;
  }
`;
export const GroupInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
