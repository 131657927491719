import styled from 'styled-components';

export const BreadcrumbsWrapper = styled.div`
  color: #737373;
  position: relative;

  .goBackBtn {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #737373;
    padding-bottom: 20px;
    cursor: pointer;
    max-width: 100px;
  }
  .cancelBtn {
    position: absolute;
    right: 0;
    top: 0;
    padding: 8px 20px;
    border-radius: 8px;
    color: #b91c1c;
    background-color: #fee2e2;
    cursor: pointer;
  }
  .breadcrumbsList {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-left: 20px;
    font-size: 14px;

    .breadcrumbLink {
      text-decoration: none;
      color: inherit;

      &:hover {
        color: ${({ theme }) => theme.gray2};
      }
    }

    :last-child {
      color: #404040;
    }
  }
`;
