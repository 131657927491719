import { useEffect, useState } from 'react';

import {
  HiOutlineChevronRight,
  HiOutlineChevronLeft,
  HiChevronDown,
  HiChevronUp,
  HiOutlineLogout,
  HiArrowSmLeft,
  HiMenu,
} from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import Footer from 'components/shared/Layouts/Footer';
import Drawer from 'components/shared/Modal/Drawer';
import { BranchCompany, BranchUser } from 'components/shared/ui/UserInfo';
import { publicWindow } from 'config/public';
import { useUserConfiguration } from 'config/themes';
import { useAuth, useAuthDispatch } from 'context/AuthProvider';

import AccountMenu from './AccountMenu';
import ExpandableAccount from './ExpandableAccount';
import {
  CloseIcon,
  NavlinksWrapper,
  NavMenuWrapper,
  NavStaticLinkWrapper,
  NavMenuToggler,
  NavMobileMenuToggler,
  ExpandableContainer,
  ExpandButton,
} from './NavMenu.style';
import NavMenuLink from './NavMenuLink';
import { NavLogo, AccountIconLine } from '../NavBar.style';

const { ssoSn } = publicWindow;
const { ssoRedirectUrl } = publicWindow;

type NavMenuProps = {
  isOverFlown: boolean;
  setMenuExpanded: (expanded: boolean) => void;
};

function NavMenu({ isOverFlown, setMenuExpanded }: NavMenuProps) {
  const [issuer, setIssuer] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const { logoPath, logoHeight } = useUserConfiguration();

  const authState = useAuth();
  const authSend = useAuthDispatch();
  const isAuthorized = authState.matches('authorized');
  const isInitialized = authState.matches('authorized.initialized');
  const { objects = [], user, branch } = authState.context;

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  useEffect(() => {
    const storedValue = localStorage.getItem('issuer');
    if (storedValue) {
      setIssuer(storedValue);
    }
    setMenuExpanded(isOpen);
  }, [isOpen]);

  function goHome() {
    setIsOpen(false);
    navigate('/');
  }

  function logout() {
    if (issuer !== undefined && issuer !== null && issuer !== '') {
      window.location.href =
        'https://sso.softonecloud.com/SAML/InitiateSingleLogout/' +
        `${ssoSn}` +
        '?issuer=' +
        `${issuer}` +
        '&redirectUrl=' +
        `${ssoRedirectUrl}`;
    }
    localStorage.clear();
    setIsOpen(false);
    authSend({ type: 'LOGOUT' });
    navigate('/', { replace: true });
  }

  if (!isAuthorized) return null;
  return (
    <NavMenuWrapper $isOverFlown={isOverFlown}>
      <Drawer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        renderHeader={() => (
          <>
            <NavLogo
              alt='app-logo'
              src={logoPath}
              $logoHeight={logoHeight}
              onClick={() => navigate('/')}
              style={{ maxWidth: '70%' }}
            />
            {/* <CloseIcon onClick={() => setIsOpen(false)} /> */}
          </>
        )}
        renderInfo={() => <></>}
        renderFooter={() => (
          <>
            <ExpandButton onClick={toggleExpand}>
              <AccountIconLine />
              Account
              {isExpanded ? <HiChevronDown /> : <HiChevronUp />}
            </ExpandButton>

            <ExpandableContainer isOpen={isExpanded}>
              <BranchUser {...{ user, branch }} />
              <BranchCompany {...{ branch }} />
              <ExpandableAccount closeMenu={() => setIsOpen(false)} />
              <div className='logoutBtn' onClick={logout}>
                Logout
                <HiOutlineLogout />
              </div>
            </ExpandableContainer>

            <Footer screen='leftMenuStandar' />
          </>
        )}
      >
        <NavlinksWrapper style={{ margin: '0' }}>
          <NavStaticLinkWrapper className='activeMenuItem' onClick={goHome}>
            Home
          </NavStaticLinkWrapper>
          {isInitialized &&
            objects.map((object: any) => (
              <NavMenuLink
                key={object.OBJECTPATH}
                object={object}
                closeMenu={() => setIsOpen(false)}
              />
            ))}
        </NavlinksWrapper>
      </Drawer>

      <NavMenuToggler
        onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
        exit={{ marginLeft: 0 }}
        animate={isOpen ? { marginLeft: 'calc(30rem + 0px)' } : { marginLeft: 0 }}
        transition={{ duration: 0.3, ease: 'linear' }}
      >
        {isOpen ? <HiOutlineChevronLeft /> : <HiOutlineChevronRight />}
      </NavMenuToggler>

      <NavMobileMenuToggler>
        <div className='floatMobileMenu'>
          <HiArrowSmLeft
            onClick={() => {
              navigate(-1);
            }}
          />
          <HiMenu onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)} />
        </div>
      </NavMobileMenuToggler>
    </NavMenuWrapper>
  );
}

export default NavMenu;
