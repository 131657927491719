import { motion } from 'framer-motion';

export function WithAnimation(Wrapped: any) {
  const { slide = 0, slideUp = 0 } = animationConfig as any;
  // eslint-disable-next-line react/display-name
  return (props: any) => (
    <motion.div
      exit={{ opacity: 0, x: slide, y: slideUp }}
      initial={{ opacity: 0, x: slide, y: slideUp }}
      animate={{ opacity: 1, x: 0, y: 0 }}
      style={{ height: '100%' }}
    >
      <Wrapped {...props} />
    </motion.div>
  );
}

export function WithAnimationList(Wrapped: any) {
  const { slide = 0, slideUp = 0 } = animationConfig as any;
  // eslint-disable-next-line react/display-name
  return (props: any) => (
    <motion.div
      exit={{ opacity: 0, x: slide, y: slideUp }}
      initial={{ opacity: 0, x: slide, y: slideUp }}
      animate={{ opacity: 1, x: 0, y: 0 }}
      style={{ height: 'calc(100% - 100px)' }}
    >
      <Wrapped {...props} />
    </motion.div>
  );
}

export const animationConfig = {
  slideUp: 30,
};

// export const RouteTransition = ({
//   children,
//   exact = false,
//   path,
//   slide = 0,
//   slideUp = 0,
//   ...rest
// }) => (
//   <Route exact={exact} path='/admang/service-center' {...rest}>
//     <MountTransition slide={slide} slideUp={slideUp}>
//       {children}
//     </MountTransition>
//   </Route>
// );
