import styled from 'styled-components';

import { device } from 'config/device';

export const THwrapper = styled.div`
  width: max-content;
  grid-gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  fill: ${(props) => props.theme.primary};
  transition: opacity 0.2s ease-in-out;
  :hover {
    opacity: 0.6;
  }
`;

export const GroupWrapper = styled.div`
  display: flex;
  transition: opacity 0.2s ease-in-out;
  :hover {
    opacity: 0.6;
  }
`;

type TablePageWrapperProps = {
  $isClickable?: boolean;
  $offset?: number;
  $height?: number;
};

export const TablePageWrapper = styled.div<TablePageWrapperProps>`
  height: 100%;
  font-size: ${(props) => props.theme.small};
  font-weight: 500;
  border: 1px solid #d4d4d4;
  border-radius: 12px;
  padding: 16px 32px;
  box-shadow: ${(props) => props.theme.shadow[3]};

  @media ${device.mobile} {
    padding: 8px 12px;
    height: calc(100% - 50px);
  }

  .container {
    width: 100%;
    max-height: ${(props) => {
      if (props.$height) return `${props.$height}rem`;
      return `calc(100% - ${props.$offset || 0}rem)`;
    }};
    position: relative;
    overflow: auto;
    @media ${device.mobile} {
      max-height: ${(props) => {
        if (props.$height) return `${props.$height}rem`;
        return `calc(100% - ${(props.$offset || 0) + 2 || 0}rem)`;
      }};
    }
  }
  table {
    width: 100%;
    padding: 1rem;
    text-align: left;
    border-collapse: collapse;
  }
  [active-row='true'] {
    background-color: ${(props) => `${props.theme.special}1A`} !important;
  }
  [is-grouped='true'] {
    background-color: ${(props) => `${props.theme.special}1A`};
  }
  [is-placeholder='true'] {
    text-align: right;
    background-color: ${(props) => `${props.theme.error}1A`};
  }
  [is-float='true'] {
    text-align: right;
  }
  thead {
    position: sticky;
    top: 0;
    tr {
      font-size: ${(props) => props.theme.small};
      background: #ffffff;

      :after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: ${(props) => props.theme.grayLight3}; /* Border color */
        pointer-events: none; /* Ensures it doesn’t interfere with table interactions */
      }
    }
    th {
      padding: 1rem 1.5rem;
      font-weight: 500;
      font-size: 1.4rem;
      color: #404040;
      svg {
        color: ${(props) => props.theme.special};
        width: 20px;
        height: 20px;
      }
    }
  }
  tbody {
    cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'auto')};
    tr {
      &:nth-child(even) {
        background-color: #f5f5f5;
      }
      &:nth-child(odd) {
        background-color: #fff;
      }
      td:first-child {
        border-top-left-radius: 8px; /* Top left corner of first column */
        border-bottom-left-radius: 8px; /* Bottom left corner of first column */
      }
      td:last-child {
        border-top-right-radius: 8px; /* Top right corner of last column */
        border-bottom-right-radius: 8px; /* Bottom right corner of last column */
      }
    }
    tr:nth-child(even) {
      background-color: #f5f5f5;
    }
    tr:nth-child(odd) {
      background-color: #fff;
    }
    td {
      padding: 1.5rem 1.2rem;
      color: #525252;
      svg {
        color: ${(props) => props.theme.special};
      }
    }
  }
`;

export const EmptyContainer = styled.div`
  padding: 5rem 0;
  text-align: center;
  font-size: ${(props) => props.theme.medium};
  color: ${(props) => props.theme.error};
`;

type MemoryTableWrapperProps = {
  $isClickable?: boolean;
  $offset?: number;
  $height?: number;
};

export const MemoryTableWrapper = styled.div<MemoryTableWrapperProps>`
  width: 100%;
  height: 100%;
  .container {
    width: 100%;
    max-height: ${(props) => {
      if (props.$height) return props.$height;
      return `calc(100% - ${props.$offset || 0}rem)`;
    }};
    position: relative;
    overflow: auto;
    padding-right: 10px;
  }

  table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    background-color: ${(props) => props.theme.white};
    [active-row='true'] {
      td:nth-child(1) {
        color: ${(props) => props.theme.text};
        // border-left: 1rem solid ${(props) => props.theme.special};
      }
    }
    thead {
      position: sticky;
      top: 0;

      tr {
        background: #ffffff;

        :after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          background-color: ${(props) => props.theme.grayLight3}; /* Border color */
          pointer-events: none; /* Ensures it doesn’t interfere with table interactions */
        }
      }
      th {
        transition: opacity 0.2s ease-in-out;
        :hover {
          opacity: 0.5;
        }
      }
    }
    th,
    td {
      font-weight: 500;
      font-size: ${(props) => props.theme.small};
      padding: 1.5rem 0.7rem;
    }
    tbody {
      tr {
        cursor: ${({ $isClickable = true }) => ($isClickable ? 'pointer' : 'auto')};
        color: ${(props) => props.theme.gray};
        td {
          :nth-child(1) {
            // border-left: 1rem solid #fff;
          }
        }
        &:hover {
          color: ${(props) => props.theme.text};
          background-color: ${(props) => `${props.theme.special}1A`} !important;
          td:nth-child(1) {
            // border-left: 1rem solid ${(props) => props.theme.special};
          }
        }

        &:nth-child(even) {
          background-color: #f5f5f5;
        }
        &:nth-child(odd) {
          background-color: #fff;
        }
        td:first-child {
          border-top-left-radius: 8px; /* Top left corner of first column */
          border-bottom-left-radius: 8px; /* Bottom left corner of first column */
        }
        td:last-child {
          border-top-right-radius: 8px; /* Top right corner of last column */
          border-bottom-right-radius: 8px; /* Bottom right corner of last column */
        }
      }
    }
  }
`;
