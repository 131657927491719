import { motion } from 'framer-motion';
import styled from 'styled-components';

import { ReactComponent as Close } from 'assets/icons/close.svg';
import { device } from 'config/device';

export const CloseIcon = styled(Close)`
  cursor: pointer;
  fill: #ecebf2;
  height: 2.5rem;
  width: 2.5rem;
`;

type NavLinkWrapperProps = {
  $isOverFlown: boolean;
};

export const NavMenuWrapper = styled.div<NavLinkWrapperProps>`
  display: ${(props) => (props.$isOverFlown ? 'block' : 'none')};
  hr {
    border-color: ${({ theme }) => `${theme.gray}90`};
  }
  position: absolute;
  top: 32px;
  left: 0;

  .menu-hamburger-icon {
    svg {
      width: 3rem;
      height: 3rem;
      background-color: ${({ theme }) => `${theme.special}`};
      color: #ffffff;
      border-top-right-radius: ${(props) => props.theme.border.button};
      border-bottom-right-radius: ${(props) => props.theme.border.button};
    }
  }
  @media ${device.tablet} {
    display: block;
  }
`;

export const NavMenuToggler = motion(styled.div`
  cursor: pointer;

  @media ${device.tablet} {
    display: none;
  }

  svg {
    width: 3rem;
    height: 3rem;
    background-color: ${({ theme }) => `${theme.special}`};
    color: #ffffff;
    border-top-right-radius: ${(props) => props.theme.border.button};
    border-bottom-right-radius: ${(props) => props.theme.border.button};
  }
`);

export const NavMobileMenuToggler = motion(styled.div`
  display: none;
  @media ${device.tablet} {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 20px;
    width: 100%;

    .floatMobileMenu {
      width: 80%;
      padding: 0 35px;
      background: #ffffff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 23px;
      height: 48px;
      // box-shadow: ${(props) => props.theme.shadow[3]};
      box-shadow:
        0 4px 13px 3px rgb(0 0 0 / 24%),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
      max-width: 320px;

      svg {
        color: ${({ theme }) => `${theme.special}`};
        width: 28px;
        height: 28px;
      }
    }
  }
`);

export const NavStaticLinkWrapper = styled.li`
  cursor: pointer;
  display: flex;
  grid-gap: 1rem;
  align-items: center;
  font-size: ${({ theme }) => theme.large};
  :hover {
    opacity: 0.6;
  }
`;

export const NavlinksWrapper = styled.ul`
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  font-size: ${(props) => props.theme.large};
  list-style: none;
  li {
    margin-bottom: 1rem;
  }
  .menu-icon {
    width: 2rem;
    height: 2rem;
    fill: currentColor;
  }
  .nav-icon {
    width: 1.5rem;
    height: 1.5rem;
    fill: currentColor;
  }
  .activeMenuItem {
    background-color: ${(props) => props.theme.special};
    color: #fff;
    padding: 8px 16px !important;
    border-radius: 12px;
    max-width: 230px;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  .nav-link {
    cursor: pointer;
    padding: 1rem 0.5rem;
    grid-gap: 1rem;
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: ${({ theme }) => theme.small};
    margin-left: 0.7rem;

    :hover {
      color: ${({ theme }) => theme.gray2};
    }
  }
`;

type ExpandableProps = {
  isOpen: boolean;
};

export const ExpandableContainer = styled.div<ExpandableProps>`
  overflow: hidden;
  max-height: ${(props) => (props.isOpen ? '200px' : '0')};
  transition: max-height 0.3s ease;

  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0;

  .actionBtn {
    cursor: pointer;
    text-decoration: none;
    color: inherit;

    &:hover {
      color: ${({ theme }) => theme.gray2};
    }
  }
  .logoutBtn {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #dc0000;
    cursor: pointer;

    svg {
      font-size: 20px;
    }
  }
`;

export const ExpandButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 1.75rem;

  width: 100%;
  max-width: 200px;
  opacity: 0.7;

  svg {
    height: 3rem;
    width: 3rem;
  }
`;
