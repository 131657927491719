import React, { useMemo } from 'react';

import { useMachine } from '@xstate/react';
import { useNavigate } from 'react-router';
import useResizeObserver from 'use-resize-observer';

import { Button } from 'components/shared/Button/Button';
import { NotificationLoading } from 'components/shared/ui/AnimatedText';
import { API } from 'config/API';
import { publicWindow } from 'config/public';
import { useAuth, useAuthDispatch } from 'context/AuthProvider';
import { LoginMachine } from 'machines/LoginMachine/LoginMachine';

import { BranchContainer } from './Branch.style';
import BranchSelection from './BranchSelection';
import { Form, FormMessage, LoginError } from '../LoginPage/Login.style';

export default function BranchPage() {
  const { appId = '2006' } = publicWindow;

  const navigate = useNavigate();
  const { ref } = useResizeObserver();

  const authState = useAuth();
  const authSend = useAuthDispatch();
  const { user, branch } = authState.context;

  const [state, send] = useMachine(LoginMachine, {
    context: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      API,
      appId,
      objs: branch.objs,
      selected: branch.selected,
      loginClientID: user.loginClientID,
    },
    actions: {
      goToHome: () => {
        navigate('/', { replace: true });
      },
    },
  });
  const { value, context } = state as any;

  function onBranchSubmit(e: any) {
    e.preventDefault();
    send({ type: 'SUBMIT_BRANCH' });
  }

  React.useEffect(() => {
    if (value === 'success') {
      authSend({
        type: 'CHANGE_BRANCH',
        clientID: context.clientID,
        branch: {
          objs: context.objs,
          selected: context.authParams,
        },
      });
    }
  }, [value, context, authSend]);

  return (
    <BranchContainer ref={ref}>
      <Form data-cy='branch-selection-form' onSubmit={onBranchSubmit}>
        <FormMessage color='tertiary'>Change company</FormMessage>
        {(() => {
          if (value === 'success' || value === 'authenticating') {
            return (
              <NotificationLoading
                showText={state.matches('success')}
                textTitle={'SUCCESS'}
              />
            );
          }
          return (
            <>
              <BranchSelection actor={context.branchActor} />
              <Button style={{ width: '100%' }} type='submit'>
                CONTINUE
              </Button>
              {context.error && <LoginError>{context.error}</LoginError>}
            </>
          );
        })()}
      </Form>
    </BranchContainer>
  );
}
