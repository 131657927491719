import styled from 'styled-components';

import { device } from 'config/device';

const PageContainer = styled.div`
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 1rem auto;
  background: #fff;
  padding: 16px 32px;
  border-radius: 12px;
  width: 100%;

  @media ${device.tablet} {
    margin: 0.5rem auto;
    padding: 16px 16px;
  }

  .stepTitle {
    font-size: 16px;
    color: ${(props) => props.theme.text2};
  }
  .stepDesc {
    color: ${(props) => props.theme.gray};
    padding-top: 10px;
  }
`;

const PageNavigation = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: 2rem 0;
  grid-gap: 1rem;
  .flex {
    display: flex;
    grid-gap: 1rem;
  }
  button {
    min-width: 150px;
  }

  @media ${device.mobile} {
    flex-wrap: nowrap;

    .flex {
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
    }
    .leftActions {
      flex-direction: column-reverse;
    }
    .rightActions {
      align-items: flex-end;
    }
    button {
      width: 100%;
      max-width: 150px;
    }
  }
`;

const H2 = styled.h2`
  border-radius: 2px;
  background: ${(props) => props.theme.special};
  text-align: center;
  opacity: 1;
  color: #fff;
  position: relative;
  padding: 2rem 0;
  font-weight: 500;
  font-size: ${(props) => props.theme.large};
`;

export { H2, PageContainer, PageNavigation };
