import { useState, useRef, useEffect, useCallback } from 'react';

import { AnimatePresence } from 'framer-motion';
import { default as ReactModal } from 'react-modal';
import { v4 as uuidv4 } from 'uuid';

import { useModalTrack } from 'context/ModalProvider';
import { useWindowSize } from 'hooks/useWindowResize';

import {
  ModalLayout,
  ArrowLeftIcon,
  BackContainer,
  ModalContent,
  ModalMidScreen,
  ModalOverlay,
  CloseIcon,
  BackWrapper,
} from './Modal.style';

let modalRoot = document.getElementById('modal');
if (!modalRoot) {
  modalRoot = document.createElement('div');
  modalRoot.setAttribute('id', 'modal');
  document.body.appendChild(modalRoot);
}
ReactModal.setAppElement('#modal');

type Props = {
  isOpen: boolean;
  children: any;
  askUser?: boolean;
  fullScreen?: boolean;
  isDisabled?: boolean;
  handleClose: () => void;
};

const Modal = ({
  isOpen,
  children,
  askUser,
  fullScreen,
  isDisabled,
  handleClose,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { current: id } = useRef(uuidv4());

  const windowSize = useWindowSize();
  const isMobile = (windowSize.width ? windowSize.width : 0) < 500;

  const { subscribe, unSubscribe } = useModalTrack();
  const [el] = useState(document.createElement('div'));

  const onClose = useCallback(() => {
    if (isDisabled) return;
    unSubscribe();
    handleClose();
  }, [isOpen, handleClose, unSubscribe]);

  useEffect(() => {
    if (isOpen) subscribe({ id, ref, onClose, fullScreen });
  }, [isOpen, fullScreen, onClose]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  useEffect(() => {
    modalRoot?.appendChild(el);
    return () => modalRoot?.removeChild(el);
  }, [el]);

  return (
    <ReactModal
      style={{
        overlay: {
          zIndex: 100,
          backgroundColor: 'inherit',
          cursor: isDisabled ? 'not-allowed' : 'default',
        },
        content: {
          border: 'none',
          backgroundColor: 'inherit',
        },
      }}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <AnimatePresence>
        {/* Disable fullscreen modal, confusion with page */}
        {/* eslint-disable-next-line no-constant-condition */}
        {(isMobile || fullScreen) && false ? (
          <ModalLayout
            ref={ref}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.1, easings: 'circInOut' }}
          >
            <BackContainer>
              <BackWrapper onClick={onClose}>
                <ArrowLeftIcon />
                <span>Back</span>
              </BackWrapper>
            </BackContainer>
            <ModalContent>{children}</ModalContent>
          </ModalLayout>
        ) : (
          <ModalOverlay
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.1, easings: 'circInOut' }}
          >
            <ModalMidScreen ref={ref} askUser={askUser}>
              <div className='closeModal'>
                <CloseIcon onClick={onClose} />
              </div>
              {children}
            </ModalMidScreen>
          </ModalOverlay>
        )}
      </AnimatePresence>
    </ReactModal>
  );
};

export default Modal;
