import React from 'react';

import { useMachine } from '@xstate/react';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import ChildSteps from 'components/ChildSteps/ChildSteps';
import { Error } from 'components/Search/Search.style';
import { ClipLoader, OverlayLoading } from 'components/shared/Loading';
import { getEditData, setData } from 'helpers/services/services.utils';
import { useInfoAndData } from 'hooks/useInfoAndData';
import { getConfigByID, getObjectByParams } from 'machines/FormMachine/FormMachine.utils';
import { ListMachine } from 'machines/ListMachine/ListMachine';
import { createLocateObject } from 'machines/shared/utils/locateInfo';

import { CalendarContainer, LoadingContainer } from './calendar-styles';
import { getAllEvents, getEventData, getEventSetData } from './calendar-utils';
import { useAuth } from '../../context/AuthProvider';

const locales = {
  'en-US': enUS,
};
const localizer = dateFnsLocalizer({
  parse,
  format,
  getDay,
  locales,
  startOfWeek,
});
const DnDCalendar = withDragAndDrop(Calendar);

export function CalendarPage() {
  const authState = useAuth();
  const { objects, config } = authState.context;

  const { objectPath, optionPath, id } = useParams();
  const [isOpen, setIsOpen] = React.useState(false);

  const currentObject = getObjectByParams(objectPath, optionPath, objects);
  const configById = getConfigByID(currentObject.OBJECTID, config);

  const [state, send] = useMachine(ListMachine, {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    context: { ...ListMachine.context, currentObject },
  });

  const browserQuery = useInfoAndData({
    query: '',
    list: currentObject.OBJSOFTONELIST,
    object: currentObject.OBJSOFTONENAME,
    filters: currentObject.OBJSOFTONEFILTERS,
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { setMutate, set } = useMutation(setData, {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onError: (error) => toast.error(error.message),
    onSuccess: () => browserQuery.refetch(),
  }) as any;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { editMutate, edit } = useMutation(getEditData, {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onError: (error) => toast.error(error.message),
    onSuccess: (editData) => {
      send({
        type: 'spawnForm',
        id: objectPath,
        key: editData.key,
        data: editData,
        config: configById,
        object: currentObject,
        globalConfig: config,
        globalObjects: objects,
        refetch: browserQuery.refetch,
      });
      setIsOpen(true);
    },
  });

  const handleClick = React.useCallback(
    async (code: any) => {
      const locateObject = createLocateObject(configById, currentObject);
      await editMutate({ key: code, locateObject, currentObject });
    },
    [configById, currentObject],
  );
  const allEvents = React.useMemo(
    () => getAllEvents(browserQuery.data),
    [browserQuery.data],
  );
  React.useEffect(() => {
    if (id) handleClick(id);
  }, [id]);

  if (state.context.childObjectRef) {
    return (
      <ChildSteps
        isOpen={isOpen}
        onClose={() => {
          edit?.reset();
          send({ type: 'CLOSE' });
          setIsOpen(false);
        }}
        actorRef={state.context.childObjectRef}
      />
    );
  }

  if (browserQuery?.status === 'error')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <Error>{browserQuery.error.message}</Error>;
  return (
    <CalendarContainer>
      <OverlayLoading
        isLoading={edit?.status === 'loading' || set?.status === 'loading'}
      />
      {(() => {
        if (!state.context.childObjectRef) return null;
        return (
          <ChildSteps
            isOpen={isOpen}
            onClose={() => {
              edit?.reset();
              send({ type: 'CLOSE' });
              setIsOpen(false);
            }}
            actorRef={state.context.childObjectRef}
          />
        );
      })()}
      <LoadingContainer>
        <ClipLoader
          size={25}
          isLoading={browserQuery.isFetching || browserQuery.status === 'loading'}
        />
      </LoadingContainer>
      <DnDCalendar
        resizable={false}
        selectable={true}
        events={allEvents}
        localizer={localizer}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        endAccessor='end'
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        startAccessor='start'
        onEventDrop={(e: any) => {
          const eventData = getEventData(configById, e);
          const fieldsData = getEventSetData(eventData, currentObject);
          setMutate({
            key: e.event.id,
            data: fieldsData,
            object: currentObject,
          });
        }}
        onSelectSlot={(e: any) => {
          const eventData = getEventData(configById, e);
          send({
            type: 'spawnNewForm',
            id: currentObject.OBJECTID,
            config: eventData,
            object: currentObject,
            globalConfig: config,
            globalObjects: objects,
            refetch: browserQuery.refetch,
          });
          setIsOpen(true);
        }}
        onSelectEvent={(e: any) => handleClick(e.id)}
      />
    </CalendarContainer>
  );
}
