import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

import { deviceNumberSizes } from 'config/device';
import { useNavigation } from 'hooks/useNavigation';
import { useWindowSize } from 'hooks/useWindowResize';

import { LinksWrapper } from './NavMenu.style';
import { NavListHeader } from '../NavBar.style';
import NavLinkLabel from '../NavLinkLabel';

type NavMenuLinkProps = {
  object: any;
  closeMenu: () => void;
};

function NavMenuLink({ object, closeMenu }: NavMenuLinkProps) {
  const { MENUOPTIONS, OBJECTNAME, ICON } = object;
  const navigate = useNavigation(object.OBJECTPATH);
  const { OBJECTPATH } = object;

  const location = useLocation();
  const { pathname } = location;

  const windowSize = useWindowSize();
  const isTabletDown =
    (windowSize.width ? windowSize.width : 0) <= deviceNumberSizes.tablet;

  function handleClick(option: any) {
    if (!option.PATH) return;
    if (isTabletDown) closeMenu();
    navigate(option);
  }

  if (MENUOPTIONS?.length === 0) return null;
  return (
    <li>
      <NavListHeader>
        {OBJECTNAME}
        {ICON}
      </NavListHeader>
      <LinksWrapper>
        {MENUOPTIONS.map((option: any, index: number) => {
          if (option.HIDDEN) return null;
          const dynamicLink = formatDynamicLink(option, OBJECTPATH);
          return (
            <div key={index}>
              <div
                data-cy={`${OBJECTNAME}-${option.TITLE}`}
                className={clsx('nav-link', { activeMenuItem: dynamicLink === pathname })}
                onClick={() => handleClick(option)}
              >
                <NavLinkLabel {...{ option, object }} />
              </div>
            </div>
          );
        })}
      </LinksWrapper>
    </li>
  );
}

function formatDynamicLink(option: any, objectPath: any) {
  let type = '';
  let itemUrl;

  if (option.TYPE === 'LIST') {
    type = 'search';
  }
  if (option.TYPE === 'CREATE') {
    type = 'new';
  }
  if (option.TYPE === 'CALENDAR') {
    type = 'calendar';
  }

  if (option.PATH && objectPath) {
    itemUrl = `/${objectPath}/${type}/${option.PATH}`;
  } else if (option.PATH) {
    itemUrl = `/${option.PATH}`;
  } else {
    itemUrl = `/${objectPath}/${type}`;
  }

  return itemUrl;
}

export default NavMenuLink;
