import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import { INPUT_MODIFER_CONFIG } from 'components/shared/Input/ConfigInput.style';

export const ConfigTextarea = styled.textarea`
  width: 100%;
  font-family: 'Roboto';
  color: ${(props) => props.theme.text};
  letter-spacing: 0.3px;
  outline: none;
  margin-top: 0.5rem;
  padding: 1.4rem 1.5rem;
  font-size: ${(props) => props.theme.small};
  font-weight: 500;
  border-radius: 2px;
  background-color: ${(props) => props.theme.grayLight2};
  box-shadow: ${({ theme }) => theme.shadow[3]};
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  resize: vertical;
  transition:
    background-color,
    box-shadow 0.2s ease-in-out;
  ${applyStyleModifiers(INPUT_MODIFER_CONFIG)}
`;
