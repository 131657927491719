import React from 'react';

import CreateButton from 'components/ChildSteps/CreateButton';
import EditButton from 'components/ChildSteps/EditButton';
import { FormContainer } from 'components/Search/Search.style';
import useBaseItemStatus from 'hooks/useBaseItemStatus';
import { Item } from 'types/Item';

import {
  Label,
  Container,
  LockedIcon,
  LabelContainer,
  ContentContainer,
} from './ConfigBaseItem.style';
import { InputRightIcon } from '../Icons/ActionIcons';
import { LockedFloatIcon } from '../Icons/InfoIcons';

type Props = {
  item: Item;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  render?: React.ReactNode | (({ baseStatus }: { baseStatus: any }) => React.ReactNode);
  children: React.ReactNode | (({ baseStatus }: { baseStatus: any }) => React.ReactNode);
  isDisabled?: boolean;
  className?: string;
};

export function ConfigBaseItem({
  item,
  leftIcon,
  rightIcon,
  render,
  children,
  isDisabled,
  className,
}: Props) {
  const baseStatus = useBaseItemStatus(item);
  const isDisableMode = item.FIELDREADONLY || item.PREVIEWMODE || isDisabled;
  const isSpecialIcon = item.FIELDTYPE === 'Bool' || item.FIELDTYPE === 'Textarea';
  return (
    <Container className={className || 'inputContainer'}>
      <LabelContainer $renderToTop={item.FIELDTYPE === 'Textarea' || !!render}>
        <Label>
          {item.FIELDCAPTION}
          {!!item.FIELDREQUIRED && '*'}
        </Label>
        {isSpecialIcon && item.FIELDREADONLY ? <LockedIcon /> : null}
        {(() => {
          if (!item.CREATENEWENTRY) return null;
          if (item.FIELDVALUE) {
            return <EditButton objectId={item.CREATENEWOBJECTID} item={item} />;
          }
          return (
            <CreateButton objectId={item.CREATENEWOBJECTID} fieldId={item.FIELDID} />
          );
        })()}
      </LabelContainer>
      <ContentContainer $hasRender={!!render} $isDisabled={Boolean(isDisableMode)}>
        <FormContainer>
          {(() => {
            if (item.FIELDREADONLY) return <LockedFloatIcon />;
            return leftIcon;
          })()}
          {(() => {
            if (typeof children === 'function') {
              return children({ baseStatus });
            }
            return children;
          })()}
          {(() => {
            if (isDisableMode) return null;
            return rightIcon && <InputRightIcon>{rightIcon}</InputRightIcon>;
          })()}
        </FormContainer>
        {(() => {
          if (typeof render === 'function') {
            return render({ baseStatus });
          }
          return render;
        })()}
      </ContentContainer>
    </Container>
  );
}
