import styled from 'styled-components';

export const BranchContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 100px;

  form {
    width: 100%;
    max-width: 500px;
    box-shadow: ${(props) => props.theme.shadow[3]};
    border-radius: 8px;
  }
`;
