import { Suspense } from 'react';

import { Loading } from 'components/shared/Loading';
import { publicWindow } from 'config/public';
import { useUserConfiguration } from 'config/themes';
import { useAuth } from 'context/AuthProvider';

import { HomeMenu } from './HomeMenu';
import { HeaderContainer, HomepageTitle } from './HomePage.style';
import Charts from '../../components/Charts/Charts';
import { WithAnimation } from '../../helpers/routeTransitions/MountTransition';
import LoginPage from '../LoginPage/LoginPage';

const AnimatedLogin = WithAnimation(LoginPage);

export default function HomePage() {
  const authState = useAuth();
  const { title } = useUserConfiguration();

  const { s1gadgets = [], objects = [] } = authState.context;

  const iAuthorized = authState.matches('authorized');

  if (!publicWindow.showHomeMenu || !s1gadgets) {
    return <Loading />;
  }

  if (!iAuthorized) {
    return <AnimatedLogin />;
  }

  return (
    <Suspense fallback={<Loading />}>
      {publicWindow.showHomeTitle && (
        <HeaderContainer>
          <HomepageTitle>{title}</HomepageTitle>
        </HeaderContainer>
      )}
      {publicWindow.showHomeMenu && <HomeMenu objects={objects} />}
      {s1gadgets.length > 0 && <Charts s1gadgets={s1gadgets} />}
    </Suspense>
  );
}
